import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Portfolio" />

    <div className="frame">
      <div className="hero row">
        <div className="col-sm-3"></div>
        <div className="col-sm-6 align-center">
          <h1>Robot Ventures</h1>
          <p>Robot Ventures is a (pre-)seed investor in fintech / crypto companies with big ambitions and contrarian worldviews.</p>
          <p>Supporting founders with industry-defining economic design, and unmatched experience.</p>
          <div>
            <a className="link" href="https://twitter.com/robotventures">@robotventures</a>
            <span className="by">by</span>
            <a className="link" href="https://twitter.com/rleshner">@rleshner</a>
            <span className="by">and</span>
            <a className="link" href="https://twitter.com/tarunchitra">@tarunchitra</a>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="items">
          <a href="https://www.aleo.org/">
            <StaticImage src="../images/portfolio_aleo.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Aleo" />
            <div className="company">
              <div className="name">Aleo</div>
            </div>
          </a>
          <a href="https://alkimiya.io/">
            <StaticImage src="../images/portfolio_alkimiya.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Alkimiya" />
            <div className="company">
              <div className="name">Alkimiya</div>
            </div>
          </a>
          <a href="https://www.argent.xyz/">
            <StaticImage src="../images/portfolio_argent.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Argent" />
            <div className="company">
              <div className="name">Argent</div>
            </div>
          </a>
          <a href="https://axelar.network/">
            <StaticImage src="../images/portfolio_axelar.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Axelar" />
            <div className="company">
              <div className="name">Axelar</div>
            </div>
          </a>
          <a href="https://www.bitski.com">
            <StaticImage src="../images/portfolio_bitski.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Bitski" />
            <div className="company">
              <div className="name">Bitski</div>
            </div>
          </a>
          <a href="https://blockfolio.com/">
            <StaticImage src="../images/portfolio_blockfolio.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Blockfolio" />
            <div className="company">
              <div className="name">Blockfolio (FTX)</div>
            </div>
          </a>
          <a href="https://www.blocknative.com/">
            <StaticImage src="../images/portfolio_blocknative.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Blocknative" />
            <div className="company">
              <div className="name">Blocknative</div>
            </div>
          </a>
          <a href="https://centrifuge.io">
            <StaticImage src="../images/portfolio_centrifuge.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Centrifuge" />
            <div className="company">
              <div className="name">Centrifuge</div>
            </div>
          </a>
          <a href="https://clipper.exchange/">
            <StaticImage src="../images/portfolio_clipper.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Clipper" />
            <div className="company">
              <div className="name">Clipper</div>
            </div>
          </a>
          <a href="https://compound.finance">
            <StaticImage src="../images/portfolio_compound.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Compound" />
            <div className="company">
              <div className="name">Compound</div>
            </div>
          </a>
          <a href="https://cozy.finance/">
            <StaticImage src="../images/portfolio_cozy.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Cozy" />
            <div className="company">
              <div className="name">Cozy Finance</div>
            </div>
          </a>
          <a href="https://dodoex.io/">
            <StaticImage src="../images/portfolio_dodo.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="DODO" />
            <div className="company">
              <div className="name">DODO</div>
            </div>
          </a>
          <a href="https://www.donut.app/">
            <StaticImage src="../images/portfolio_donut.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Donut" />
            <div className="company">
              <div className="name">Donut</div>
            </div>
          </a>
          <a href="https://www.drift.trade/">
            <StaticImage src="../images/portfolio_drift.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Drift" />
            <div className="company">
              <div className="name">Drift</div>
            </div>
          </a>
          <a href="https://element.fi/">
            <StaticImage src="../images/portfolio_element.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Element" />
            <div className="company">
              <div className="name">Element Finance</div>
            </div>
          </a>
          <a href="https://elementus.io">
            <StaticImage src="../images/portfolio_elementus.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Elementus" />
            <div className="company">
              <div className="name">Elementus</div>
            </div>
          </a>
          <a href="https://www.emptyset.finance/">
            <StaticImage src="../images/portfolio_esd.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="ESD" />
            <div className="company">
              <div className="name">Empty Set Dollar</div>
            </div>
          </a>
          <a href="https://fei.money/">
            <StaticImage src="../images/portfolio_fei.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Fei" />
            <div className="company">
              <div className="name">Fei Protocol</div>
            </div>
          </a>
          <a href="https://github.com/flashbots/pm">
            <StaticImage src="../images/portfolio_flashbots.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Flashbots" />
            <div className="company">
              <div className="name">Flashbots</div>
            </div>
          </a>
          <a href="https://flexa.network/">
            <StaticImage src="../images/portfolio_amp.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="AMP" />
            <div className="company">
              <div className="name">Flexa Network</div>
            </div>
          </a>
          <a href="https://app.fractional.art/">
            <StaticImage src="../images/portfolio_fractional.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Fractional" />
            <div className="company">
              <div className="name">Fractional</div>
            </div>
          </a>
          <a href="https://frax.finance/">
            <StaticImage src="../images/portfolio_frax.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Frax" />
            <div className="company">
              <div className="name">Frax</div>
            </div>
          </a>
          <a href="https://futureswap.com/">
            <StaticImage src="../images/portfolio_futureswap.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Futureswap" />
            <div className="company">
              <div className="name">Futureswap</div>
            </div>
          </a>
          <a href="https://gauntlet.network/">
            <StaticImage src="../images/portfolio_gauntlet.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Gauntlet" />
            <div className="company">
              <div className="name">Gauntlet</div>
            </div>
          </a>
          <a href="https://goldfinch.finance/">
            <StaticImage src="../images/portfolio_goldfinch.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Goldfinch" />
            <div className="company">
              <div className="name">Goldfinch</div>
            </div>
          </a>
          <a href="https://www.greenwood.finance/">
            <StaticImage src="../images/portfolio_greenwood.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Greenwood" />
            <div className="company">
              <div className="name">Greenwood</div>
            </div>
          </a>
          <a href="https://horizon.finance/">
            <StaticImage src="../images/portfolio_horizon.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Horizon" />
            <div className="company">
              <div className="name">Horizon</div>
            </div>
          </a>
          <a href="https://hummingbot.io/">
            <StaticImage src="../images/portfolio_hummingbot.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Hummingbot" />
            <div className="company">
              <div className="name">Hummingbot</div>
            </div>
          </a>
          <a href="https://imperiipartners.com/">
            <StaticImage src="../images/robot.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Robot" />
            <div className="company">
              <div className="name">Imperii Partners</div>
            </div>
          </a>
          <a href="https://instadapp.io/">
            <StaticImage src="../images/portfolio_instadapp.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Instadapp" />
            <div className="company">
              <div className="name">Instadapp</div>
            </div>
          </a>
          <a href="https://www.jetprotocol.io/">
            <StaticImage src="../images/portfolio_jet.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Jet" />
            <div className="company">
              <div className="name">Jet</div>
            </div>
          </a>
          <a href="https://kava.io/">
            <StaticImage src="../images/portfolio_kava.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Kava" />
            <div className="company">
              <div className="name">Kava</div>
            </div>
          </a>
          <a href="https://keyless.io">
            <StaticImage src="../images/portfolio_keyless.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Keyless" />
            <div className="company">
              <div className="name">Keyless</div>
            </div>
          </a>
          <a href="https://layerzero.network/">
            <StaticImage src="../images/portfolio_layerzero.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Layer Zero" />
            <div className="company">
              <div className="name">Layer Zero</div>
            </div>
          </a>
          <a href="https://lido.fi/">
            <StaticImage src="../images/portfolio_lido.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Lido" />
            <div className="company">
              <div className="name">Lido</div>
            </div>
          </a>
          <a href="https://www.liquity.org/">
            <StaticImage src="../images/portfolio_liquity.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Liquity" />
            <div className="company">
              <div className="name">Liquity</div>
            </div>
          </a>
          <a href="https://www.lyra.finance/">
            <StaticImage src="../images/portfolio_lyra.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Lyra" />
            <div className="company">
              <div className="name">Lyra</div>
            </div>
          </a>
          <a href="https://mellow.finance/">
            <StaticImage src="../images/portfolio_mellow.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Mellow" />
            <div className="company">
              <div className="name">Mellow</div>
            </div>
          </a>
          <a href="https://mirror.finance/">
            <StaticImage src="../images/portfolio_mirror.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Mirror" />
            <div className="company">
              <div className="name">Mirror</div>
            </div>
          </a>
          <a href="https://multis.co/">
            <StaticImage src="../images/portfolio_multis.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Multis" />
            <div className="company">
              <div className="name">Multis</div>
            </div>
          </a>
          <a href="https://namebase.io/">
            <StaticImage src="../images/portfolio_namebase.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Namebase" />
            <div className="company">
              <div className="name">Namebase</div>
            </div>
          </a>
          <a href="https://nansen.ai/">
            <StaticImage src="../images/portfolio_nansen.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Nansen" />
            <div className="company">
              <div className="name">Nansen</div>
            </div>
          </a>
          <a href="https://t.me/nebula_protocol">
            <StaticImage src="../images/portfolio_nebula.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Nebula" />
            <div className="company">
              <div className="name">Nebula</div>
            </div>
          </a>
          <a href="https://neon-labs.org/">
            <StaticImage src="../images/portfolio_neon.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Neon" />
            <div className="company">
              <div className="name">Neon</div>
            </div>
          </a>
          <a href="https://nftx.org/">
            <StaticImage src="../images/portfolio_nftx.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="NFTX" />
            <div className="company">
              <div className="name">NFTX</div>
            </div>
          </a>
          <a href="https://optimism.io/">
            <StaticImage src="../images/portfolio_optimism.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Optimism" />
            <div className="company">
              <div className="name">Optimism</div>
            </div>
          </a>
          <a href="https://www.opyn.co/">
            <StaticImage src="../images/portfolio_opyn.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Opyn" />
            <div className="company">
              <div className="name">Opyn</div>
            </div>
          </a>
          <a href="https://www.orcaprotocol.org/">
            <StaticImage src="../images/portfolio_orca.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Orca" />
            <div className="company">
              <div className="name">Orca</div>
            </div>
          </a>
          <a href="https://parsec.finance/">
            <StaticImage src="../images/portfolio_parsec.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Parsec" />
            <div className="company">
              <div className="name">Parsec</div>
            </div>
          </a>
          <a href="https://www.point.app/">
            <StaticImage src="../images/portfolio_point.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Point" />
            <div className="company">
              <div className="name">Point</div>
            </div>
          </a>
          <a href="https://polymarket.com/">
            <StaticImage src="../images/portfolio_polymarket.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Polymarket" />
            <div className="company">
              <div className="name">Polymarket</div>
            </div>
          </a>
          <a href="https://pooltogether.com/">
            <StaticImage src="../images/portfolio_pooltogether.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="PoolTogether" />
            <div className="company">
              <div className="name">PoolTogether</div>
            </div>
          </a>
          <a href="https://potion.fi/">
            <StaticImage src="../images/portfolio_potion.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Potion" />
            <div className="company">
              <div className="name">Potion</div>
            </div>
          </a>
          <a href="https://primitive.finance/">
            <StaticImage src="../images/portfolio_primitive.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Primitive" />
            <div className="company">
              <div className="name">Primitive</div>
            </div>
          </a>
          <a href="https://www.rain.bh/">
            <StaticImage src="../images/portfolio_rain.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Rain" />
            <div className="company">
              <div className="name">Rain</div>
            </div>
          </a>
          <a href="https://twitter.com/hi_Reverie">
            <StaticImage src="../images/robot.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Robot" />
            <div className="company">
              <div className="name">Reverie</div>
            </div>
          </a>
          <a href="https://www.ribbon.finance/">
            <StaticImage src="../images/portfolio_ribbon.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Ribbon" />
            <div className="company">
              <div className="name">Ribbon</div>
            </div>
          </a>
          <a href="https://saddle.finance/">
            <StaticImage src="../images/portfolio_saddle.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Saddle" />
            <div className="company">
              <div className="name">Saddle</div>
            </div>
          </a>
          <a href="https://sense.finance/">
            <StaticImage src="../images/portfolio_sense.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Sense" />
            <div className="company">
              <div className="name">Sense</div>
            </div>
          </a>
          <a href="https://tryshowtime.com/">
            <StaticImage src="../images/portfolio_showtime.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Showtime" />
            <div className="company">
              <div className="name">Showtime</div>
            </div>
          </a>
          <a href="https://sirenmarkets.com/">
            <StaticImage src="../images/portfolio_siren.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Siren" />
            <div className="company">
              <div className="name">Siren</div>
            </div>
          </a>
          <a href="http://skolem.tech/">
            <StaticImage src="../images/robot.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Robot" />
            <div className="company">
              <div className="name">Skolem Labs</div>
            </div>
          </a>
          <a href="https://slingshot.finance/">
            <StaticImage src="../images/portfolio_slingshot.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Slingshot" />
            <div className="company">
              <div className="name">Slingshot</div>
            </div>
          </a>
          <a href="https://syndicateprotocol.org/">
            <StaticImage src="../images/portfolio_syndicate.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Syndicate" />
            <div className="company">
              <div className="name">Syndicate</div>
            </div>
          </a>
          <a href="https://www.withtally.com/">
            <StaticImage src="../images/portfolio_tally.png"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Tally" />
            <div className="company">
              <div className="name">Tally</div>
            </div>
          </a>
          <a href="https://www.vauld.com/">
            <StaticImage src="../images/portfolio_vauld.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Vauld" />
            <div className="company">
              <div className="name">Vauld</div>
            </div>
          </a>
          <a href="https://volmex.finance/">
            <StaticImage src="../images/portfolio_volmex.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Volmex" />
            <div className="company">
              <div className="name">Volmex</div>
            </div>
          </a>
          <a href="https://yieldprotocol.com/">
            <StaticImage src="../images/portfolio_yield.jpg"
              width={50}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Yield Protocol" />
            <div className="company">
              <div className="name">Yield Protocol</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
